.spinner {
    width: 48.2842712474619px;
    height: 48.2842712474619px;
    margin-left: -24.14213562373095px;
    margin-top: -24.14213562373095px;
    border-radius: 100%;
    -webkit-animation-name: spinner;
    animation-name: spinner;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
}
.spinner .side {
    display: block;
    width: 6px;
    height: 20px;
    background-color: #046380;
    margin: 2px;
    position: absolute;
    border-radius: 50%;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
}
.spinner .side:nth-child(1),
.spinner .side:nth-child(5) {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-name: rotate0;
    animation-name: rotate0;
}
.spinner .side:nth-child(3),
.spinner .side:nth-child(7) {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation-name: rotate90;
    animation-name: rotate90;
}
.spinner .side:nth-child(2),
.spinner .side:nth-child(6) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation-name: rotate45;
    animation-name: rotate45;
}
.spinner .side:nth-child(4),
.spinner .side:nth-child(8) {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-animation-name: rotate135;
    animation-name: rotate135;
}
.spinner .side:nth-child(1) {
    top: 24.14213562373095px;
    left: 48.2842712474619px;
    margin-left: -3px;
    margin-top: -10px;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
.spinner .side:nth-child(2) {
    top: 41.21320343109277px;
    left: 41.21320343109277px;
    margin-left: -3px;
    margin-top: -10px;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
.spinner .side:nth-child(3) {
    top: 48.2842712474619px;
    left: 24.14213562373095px;
    margin-left: -3px;
    margin-top: -10px;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
.spinner .side:nth-child(4) {
    top: 41.21320343109277px;
    left: 7.07106781636913px;
    margin-left: -3px;
    margin-top: -10px;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
.spinner .side:nth-child(5) {
    top: 24.14213562373095px;
    left: 0px;
    margin-left: -3px;
    margin-top: -10px;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
.spinner .side:nth-child(6) {
    top: 7.07106781636913px;
    left: 7.07106781636913px;
    margin-left: -3px;
    margin-top: -10px;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
.spinner .side:nth-child(7) {
    top: 0px;
    left: 24.14213562373095px;
    margin-left: -3px;
    margin-top: -10px;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
.spinner .side:nth-child(8) {
    top: 7.07106781636913px;
    left: 41.21320343109277px;
    margin-left: -3px;
    margin-top: -10px;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
@-webkit-keyframes rotate0 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    60% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@keyframes rotate0 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    60% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@-webkit-keyframes rotate90 {
    0% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    60% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}
@keyframes rotate90 {
    0% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    60% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}
@-webkit-keyframes rotate45 {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    60% {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }
    100% {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }
}
@keyframes rotate45 {
    0% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    60% {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }
    100% {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }
}
@-webkit-keyframes rotate135 {
    0% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    60% {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
    }
    100% {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
    }
}
@keyframes rotate135 {
    0% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    60% {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
    }
    100% {
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
    }
}
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
