.loaderContainer {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9999;
}

.progressBarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.progressBarLabel {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    position: absolute;
}

.progressBar {
    background-color: #aaa;
    width: 200px;
}
